<div *transloco="let t">
  <div class="text-xs mt-3 text-center">
    <em>* P.R. = Performance renseignée lors de l'inscription</em><br />
    <em>* S.B. = Season best récupérée de la DB beAthletics</em><br />
    <em>* L.B. = Last season best récupérée de la DB beAthletics</em><br />
    <em>* A.B. = Meilleure performance de l'athlète dans la DB beAthletics</em>
  </div>

  @if (dataSource) {
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            color="primary"
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [disabled]="row.status === itemStatus.REJECTED || row.status === itemStatus.VALIDATED"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ t('BASIC_ATHLETE') }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.name }}
          @if (row.relay) {
            @for (r of row.relay; track r) {
              <div class="text-sm italic text-gray-500">{{ r.relayOrder }}. {{ r.name }}</div>
            }
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ t('BASIC_CATEGORY') }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.category || '-' }}
          @if (row.relay) {
            @for (r of row.relay; track r) {
              <div class="text-sm italic text-gray-500">
                {{ r.category }}
              </div>
            }
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="club">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ t('BASIC_CLUB') }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.club || '-' }}
          @if (row.relay) {
            @for (r of row.relay; track r) {
              <div class="text-sm italic text-gray-500">
                {{ r.club }}
              </div>
            }
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="userPerf">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>P.R. *</th>
        <td mat-cell *matCellDef="let row">
          {{ row.userPerf ? (row.userPerf | perfFormatter: row.perfType : row.group) : '-' }}
          @if (row.relay) {
            @for (r of row.relay; track r) {
              <div class="text-sm italic text-gray-500">
                {{ r.userPerf ? (r.userPerf | perfFormatter: r.perfType : r.group) : '-' }}
              </div>
            }
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="seasonBest">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.B. *</th>
        <td mat-cell *matCellDef="let row">
          {{ row.seasonBest ? (row.seasonBest | perfFormatter: row.perfType : row.group) : '-' }}
          @if (row.relay) {
            @for (r of row.relay; track r) {
              <div class="text-sm italic text-gray-500">
                {{ r.seasonBest ? (r.seasonBest | perfFormatter: r.perfType : r.group) : '-' }}
              </div>
            }
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="lastSeasonBest">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>L.B. *</th>
        <td mat-cell *matCellDef="let row">
          {{ row.lastSeasonBest ? (row.lastSeasonBest | perfFormatter: row.perfType : row.group) : '-' }}
          @if (row.relay) {
            @for (r of row.relay; track r) {
              <div class="text-sm italic text-gray-500">
                {{ r.lastSeasonBest ? (r.lastSeasonBest | perfFormatter: r.perfType : r.group) : '-' }}
              </div>
            }
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="personalBest">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>A.B. *</th>
        <td mat-cell *matCellDef="let row">
          {{ row.personalBest ? (row.personalBest | perfFormatter: row.perfType : row.group) : '-' }}
          @if (row.relay) {
            @for (r of row.relay; track r) {
              <div class="text-sm italic text-gray-500">
                {{ r.personalBest ? (r.personalBest | perfFormatter: r.perfType : r.group) : '-' }}
              </div>
            }
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ t('BASIC_COMMENT') }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.comment || '-' }}
          @if (row.relay) {
            @for (r of row.relay; track r) {
              <div class="text-sm italic text-gray-500">
                {{ r.comment || '-' }}
              </div>
            }
          }
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="disciplineTableCols"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: disciplineTableCols"
        [style.color]="
          row.status === itemStatus.REJECTED ? 'red' : row.status === itemStatus.VALIDATED ? 'green' : 'black'
        "
      ></tr>
    </table>
  }
  <hr />
  <div class="pt-6 flex justify-between">
    <button mat-raised-button color="warn" (click)="treatSelection(false)">
      {{ t('REGISTRATION_REFUSE_SELECTION') }}
    </button>
    <button mat-raised-button color="primary" (click)="treatSelection(true)">
      {{ t('REGISTRATION_VALIDATE_SELECTION') }}
    </button>
    <button mat-raised-button class="ml-4" mat-dialog-close>
      {{ t('BASIC_CLOSE') }}
    </button>
  </div>
</div>
